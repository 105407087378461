export const missionNames = [
  "Hack the Mainframe",
  "Rescue the VIP",
  "Retrieve the Data Chip",
  "Eliminate the Target",
  "Escort the Convoy",
  "Sabotage the Facility",
  "Infiltrate the Corporate HQ",
  "Protect the Informant",
  "Secure the Prototype",
  "Intercept the Transmission",
  "Destroy the Drone Swarm",
  "Smuggle the Goods",
  "Neutralize the Guards",
  "Capture the Bounty",
  "Decrypt the Code",
  "Defuse the Bomb",
  "Hack the Security Network",
  "Rescue the Hostages",
  "Retrieve the Stolen Plans",
  "Eliminate the Rogue AI",
  "Guard the Safe House",
  "Sabotage the Power Grid",
  "Infiltrate the Rebel Base",
  "Protect the Hacker",
  "Secure the Cyberware",
  "Intercept the Black Market Deal",
  "Destroy the Enemy Turret",
  "Smuggle the Weapon Cache",
  "Neutralize the Security Drones",
  "Capture the Fugitive",
  "Decrypt the Encrypted Message",
  "Defuse the Chemical Bomb",
  "Hack the Financial Network",
  "Rescue the Prisoner",
  "Retrieve the Stolen Cyberdeck",
  "Eliminate the Cyber Assassin",
  "Guard the VIP",
  "Sabotage the Communications Tower",
  "Infiltrate the Secret Lab",
  "Protect the Witness",
  "Secure the Hacked Data",
  "Intercept the Spy",
  "Destroy the Surveillance Cameras",
  "Smuggle the Illegal Implants",
  "Neutralize the Cyber Thugs",
  "Capture the Cyber Criminal",
  "Decrypt the Corporate Secrets",
  "Defuse the EMP Device",
  "Hack the Satellite Network",
  "Rescue the Rebel Leader",
  "Retrieve the Prototype Cyberware",
  "Eliminate the Mob Boss",
  "Guard the Data Center",
  "Sabotage the Weapon Facility",
  "Infiltrate the Cyber Syndicate",
  "Protect the Journalist",
  "Secure the Hacked Files",
  "Intercept the Courier",
  "Destroy the Enemy Network",
  "Smuggle the Rare Cyberparts",
  "Neutralize the Armed Gang",
  "Capture the Cybernetic Bounty",
  "Decrypt the Hidden Files",
  "Defuse the Viral Bomb",
  "Hack the Military Network",
  "Rescue the Captive Scientist",
  "Retrieve the Experimental Drug",
  "Eliminate the Hacker Group",
  "Guard the Tech Lab",
  "Sabotage the Black Ops Base",
  "Infiltrate the Underground Bunker",
  "Protect the Scientist",
  "Secure the Confidential Data",
  "Intercept the Encrypted Comm",
  "Destroy the AI Core",
  "Smuggle the Stolen Tech",
  "Neutralize the Cybernetic Soldiers",
  "Capture the Terrorist Leader",
  "Decrypt the Military Plans",
  "Defuse the Biohazard",
  "Hack the City Network",
  "Rescue the Undercover Agent",
  "Retrieve the Lost Tech",
  "Eliminate the Terrorist Cell",
  "Guard the Research Facility",
  "Sabotage the Advanced AI",
  "Infiltrate the Enemy Camp",
  "Protect the Diplomat",
  "Secure the Advanced Chip",
  "Intercept the High-Value Target",
  "Destroy the Enemy Robotics",
  "Smuggle the Bio-Weapons",
  "Neutralize the Crime Lord",
  "Capture the Escaped Convict",
  "Decrypt the Surveillance Data",
  "Defuse the Explosive Device",
  "Extract the Asset",
  "Disable the Security System",
  "Retrieve the AI Core",
  "Escort the VIP",
  "Hack the Traffic Network",
  "Rescue the Cybernetic Engineer",
  "Eliminate the Street Gang",
  "Secure the Prototype Weapon",
  "Intercept the Black Ops Team",
  "Sabotage the Surveillance Drone",
  "Infiltrate the Corporate Vault",
  "Protect the Cyber Priest",
  "Steal the Biotech Sample",
  "Neutralize the Hostile Mercs",
  "Capture the Drone Operator",
  "Decrypt the Stolen Data",
  "Retrieve the Hacked Intel",
  "Hack the Biotech Lab",
  "Rescue the Cybernetic Hacker",
  "Eliminate the Syndicate Leader",
  "Guard the Shipment",
  "Sabotage the Cybernetic Lab",
  "Infiltrate the Research Facility",
  "Protect the Cyber Rebel",
  "Secure the Cybernetic Prosthetics",
  "Intercept the Hacked Comm",
  "Destroy the AI Surveillance",
  "Smuggle the Cyber Drugs",
  "Neutralize the Cyber Spy",
  "Capture the AI Programmer",
  "Decrypt the Secure Files",
  "Defuse the Cyber Bomb",
  "Hack the Transportation Grid",
  "Rescue the Corporate Spy",
  "Retrieve the Experimental Weapon",
  "Eliminate the Corporate Spy",
  "Guard the Safe House",
  "Sabotage the Cyber Warfare Facility",
  "Infiltrate the Cyber Cartel",
  "Protect the Data Courier",
  "Secure the Hacked Robotics",
  "Intercept the Stealth Drone",
  "Destroy the Secret Facility",
  "Smuggle the Black Market Goods",
  "Neutralize the Cyborg Soldier",
  "Capture the Tech Smuggler",
  "Decrypt the Encrypted Drone",
  "Defuse the Poison Gas",
  "Hack the Police Network",
  "Rescue the Cybernetic Scientist",
  "Retrieve the Cybernetic Implant",
  "Eliminate the Enemy Sniper",
  "Guard the Encryption Keys",
  "Sabotage the Robotic Assembly Line",
  "Infiltrate the Digital Fortress",
  "Protect the VIP Client",
  "Secure the Stolen Cyberware",
  "Intercept the Data Thieves",
  "Destroy the Cybernetic Weapons",
  "Smuggle the Cybernetic Armor",
  "Neutralize the Threat",
  "Capture the Black Market Dealer",
  "Decrypt the Military Database",
  "Defuse the Toxic Waste",
  "Hack the AI Network",
  "Rescue the Undercover Cop",
  "Retrieve the Nanotech",
  "Eliminate the Enemy Hacker",
  "Guard the Secure Location",
  "Sabotage the Enemy Network",
  "Infiltrate the Secret Hideout",
  "Protect the Hacker Team",
  "Secure the Data Core",
  "Intercept the Enemy Intel",
  "Destroy the Weapon Cache",
  "Smuggle the Prototype Tech",
  "Neutralize the Cybernetic Guard",
  "Capture the Enemy Leader",
  "Decrypt the Hidden Codes",
  "Defuse the Time Bomb",
  "Hack the Corporate Database",
  "Rescue the Corporate Executive",
  "Retrieve the Classified Data",
  "Eliminate the Rogue Agent",
  "Guard the AI Core",
  "Sabotage the Drone Factory",
  "Infiltrate the Cyber Syndicate Base",
  "Protect the Tech Specialist",
  "Secure the Hacked Mainframe",
  "Intercept the Data Packet",
  "Destroy the Enemy Bot",
  "Smuggle the Hacked Chip",
  "Neutralize the Cyber Assassin",
  "Capture the Tech Saboteur",
  "Decrypt the Hidden Data",
  "Defuse the Radiation Device",
  "Hack the Communications Hub",
  "Rescue the CEO",
  "Retrieve the Neural Implant",
  "Eliminate the Hostile AI",
  "Guard the Digital Vault",
  "Sabotage the Security Grid",
  "Infiltrate the Military Base",
  "Protect the Cyber Scout",
  "Secure the Cybernetic Asset",
  "Intercept the Cyber Attack",
  "Destroy the Enemy Server",
  "Smuggle the AI Core",
  "Neutralize the Corporate Spy",
  "Capture the Cyber Thief",
  "Decrypt the Top-Secret Files",
  "Defuse the Explosive Cargo",
  "Hack the Drone Network",
  "Rescue the Programmer",
  "Retrieve the Cybernetic Blueprints",
  "Eliminate the Data Breach",
  "Guard the Hacking Tools",
  "Sabotage the AI Factory",
  "Infiltrate the Enemy Base",
  "Protect the Tech Operative",
  "Secure the Encrypted Data",
  "Intercept the High-Value Intel",
  "Destroy the Enemy Data Center",
  "Smuggle the Cyber Enhancements",
  "Neutralize the Hacker Group",
  "Capture the Enemy Spy",
  "Decrypt the Cyber Threat",
  "Defuse the Laser Trap",
  "Hack the Defense Network",
  "Rescue the Cybernetic Detective",
  "Retrieve the AI Algorithm",
  "Eliminate the AI Threat",
  "Guard the Data Link",
  "Sabotage the Data Transfer",
  "Infiltrate the Secret Base",
  "Protect the Cyber Activist",
  "Secure the Digital Evidence",
  "Intercept the AI Program",
  "Destroy the Rogue Bot",
  "Smuggle the Cybernetic Enhancement",
  "Neutralize the Corporate Operative",
  "Capture the Saboteur",
  "Decrypt the Government Files",
  "Defuse the Fire Trap",
  "Hack the Robotic Network",
  "Rescue the Cybernetic Kid",
  "Retrieve the Hacked Files",
  "Eliminate the AI Controller",
  "Guard the AI Programmer",
  "Sabotage the Tech Facility",
  "Infiltrate the Digital Network",
  "Protect the Data Scientist",
  "Secure the Secret Plans",
  "Intercept the Covert Mission",
  "Destroy the Data Fortress",
  "Smuggle the AI Blueprint",
  "Neutralize the Cyber Spy",
  "Capture the Hacker Leader",
  "Decrypt the AI Network",
  "Defuse the Tech Bomb",
  "Hack the Financial System",
  "Rescue the Tech Guru",
  "Retrieve the Cybernetic Tech",
  "Eliminate the Spy Network",
  "Guard the Data Center",
  "Sabotage the Security Protocol",
  "Infiltrate the Robotic Lab",
  "Protect the Tech Whiz",
  "Secure the Digital Blueprint",
  "Intercept the Enemy Hack",
  "Destroy the Robotic Threat",
  "Smuggle the Secret Data",
  "Neutralize the AI Controller",
  "Capture the Data Broker",
  "Decrypt the Classified Network",
  "Defuse the Cyber Explosives",
  "Hack the Covert Ops Network",
  "Rescue the Cybernetic Survivor",
  "Retrieve the Stolen Secrets",
  "Eliminate the Underworld Boss",
  "Guard the Tech Hideout",
  "Sabotage the Covert Facility",
  "Infiltrate the Secret Ops",
  "Protect the VIP Scientist",
  "Secure the AI Mainframe",
  "Intercept the Black Market Shipment",
  "Destroy the Enemy Network",
  "Smuggle the Top-Secret Tech",
  "Neutralize the Data Snatchers",
  "Capture the Rogue Hacker",
  "Decrypt the Hidden Files",
  "Defuse the Network Trap",
];
